<template>
  <div id="app">
    <div class="main">
      <!--上部-->
      <div class="waper bg">
        <div class="left-part">
          <h1>SpiderNet VPN</h1>
          <ul class="description">
            <li><strong>3</strong>s<br><span>Instant Connect</span></li>
            <li><strong>∞</strong><br><span>Permanent Free</span></li>
            <li><strong>1000</strong>+<br><span>Apps Boosted</span></li>
          </ul>

          <div class="txt">
            <p>SpiderNet VPN is your go-to solution for secure and unrestricted internet access. With advanced
              encryption and lightning-fast connections, it protects your privacy and ensures seamless browsing. It
              allows you to bypass geo-restrictions easily, provides access to unlimited digital content, and guarantees
              top-notch protection for your online activities. Simplified, SpiderNet VPN is your key to a secure and
              boundless internet experience.</p>
          </div>

          <div class="down-wrapper">
            <div class="btn-down" @click="downloadApp(googlePlayUrl)"><i class="icon-gp"></i> <span
                class="text-gp"> Google Play </span></div>
            <div class="btn-down" @click="downloadApp(androidUrl)"><i class="icon-android"></i> <span
                class="text-android"> Android </span></div>
          </div>
        </div>
        <div class="right-part">
          <svg width="600pt" height="600pt"  class="ellip">
            <image xlink:href="./assets/tt_s.png" width="300pt" height="300pt" x="150pt" y="100pt" style="opacity: 0.6;">
            </image>
          </svg>
        </div>
      </div>

      <!-- 动画 -->
      <div class="waper">
        <div style="width: 100%;">
          <h1>Popular App' Play As You Like'</h1>
        </div>
        <div width="100%">
          <svg viewBox="0,0,800,550" preserveAspectRatio="xMidYMid meet"
            class="iehide showSvg">
            <g class="blingcircle">
              <circle cx="300pt" cy="200pt" r="80pt" stroke-width="2" stroke="rgba(100,128,253,0.15)" fill="none">
              </circle>
              <circle cx="300pt" cy="200pt" r="130pt" stroke-width="2" stroke="rgba(100,128,253,0.1)" fill="none">
              </circle>
              <circle cx="300pt" cy="200pt" r="180pt" stroke-width="2" stroke="rgba(100,128,253,0.05)" fill="none">
              </circle>
            </g>
            <g class="bling blingcircle">
              <image xlink:href="./assets/speed_app_1.png" x="-50pt" y="280pt" width="140pt" height="140pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;-50pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="180pt;280pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
              <image xlink:href="./assets/speed_app_2.png" x="0pt" y="100pt" width="80pt" height="80pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;0pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="160pt;100pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
              <image xlink:href="./assets/speed_app_3.png" x="70pt" y="240pt" width="120pt" height="120pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;70pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="160pt;160pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
              <image xlink:href="./assets/speed_app_4.png" x="400pt" y="280pt" width="90pt" height="90pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;400pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="160pt;280pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
              <image xlink:href="./assets/speed_app_5.png" x="480pt" y="140pt" width="120pt" height="120pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;480pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="160pt;140pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
              <image xlink:href="./assets/speed_app_6.png" x="550pt" y="280pt" width="140pt" height="140pt"
                class="svgimage">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="260pt;550pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="160pt;280pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </image>
            </g>
            <g class="bling blingcircle">
              <defs>
                <linearGradient id="redbot" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style="stop-color: rgb(234, 67, 53);"></stop>
                  <stop offset="100%" style="stop-color: rgb(234, 67, 53);"></stop>
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="bluebot" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style="stop-color: rgb(0, 102, 217); stop-opacity: 1;"></stop>
                  <stop offset="100%" style="stop-color: rgb(0, 102, 217); stop-opacity: 1;"></stop>
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="yellowbot" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style="stop-color: rgb(255, 170, 69); stop-opacity: 1;"></stop>
                  <stop offset="100%" style="stop-color: rgb(255, 170, 69); stop-opacity: 1;"></stop>
                </linearGradient>
              </defs>
              <circle cx="-30pt" cy="180pt" r="4pt" fill="url(#bluebot)" class="elliptop">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;40pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;100pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </circle>
              <circle cx="170pt" cy="260pt" r="8pt" fill="url(#yellowbot)" class="elliptop">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;40pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;210pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </circle>
              <circle cx="400pt" cy="140pt" r="2pt" fill="url(#redbot)" class="elliptop">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;40pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;210pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </circle>
              <circle cx="520pt" cy="280pt" r="4pt" fill="url(#bluebot)" class="elliptop">
                <animate attributeName="x" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;40pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
                <animate attributeName="y" attributeType="XML" repeatCount="1" keyTimes="0;1" values="200pt;140pt"
                  begin="2.5s" dur="1s" fill="freeze"></animate>
              </circle>
            </g> <svg width="600pt" height="600pt" x="-2pt" class="ellip">
              <g class="blingcircle">
                <image xlink:href="./assets/icon_1.png" width="80pt" height="80pt" x="260pt" y="160pt"
                  class="hiddensvg enimgsvg svgimage"></image>
              </g>
            </svg>
          </svg>
        </div>
      </div>

      <!-- 介绍-->
      <div class="waper flex">
        <div class="text">
          <h2>Protecting your privacy</h2>
          <ul>
            <li><span>The VPN service conceals your real IP address, providing a new one. This makes tracking your
                online activities difficult.</span> </li>
            <li><span>VPN protects your data with strong encryption algorithms, keeping sensitive information like your
                online activities, personal information, and passwords secure, especially on public WiFi.</span></li>
            <li><span>SpiderNet VPN ensures all your traffic is transmitted over unencrypted networks, making it
                impossible for ISPs, governments, or potential hackers to spy on your online behaviors.</span></li>
          </ul>
        </div>
        <div class="img">
          <img src="./assets/online_private_s.png" width="100%">
        </div>
      </div>

      <!-- 介绍-->
      <div class="waper flex">
        <div class="img">
          <img src="./assets/geo_restriction_s.png" width="100%">
        </div>
        <div class="text">
          <h2>Geo Restriction</h2>
          <p>SpiderNet VPN protects your online activities by restricting access to certain countries and regions.</p>
        </div>
      </div>
    </div>


    <footer>
      <p>All rights reserved © 2024 SpiderNet VPN</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      googlePlayUrl: "https://play.google.com/store/apps/details?id=com.spidernet.vpn",
      androidUrl: "/apk/spidernet_guanfang_1000_1.2.6.apk"
    }
  },

  methods: {
    downloadApp(url) {    
        window.open(url, "_blank");
    }

  }
}
</script>


<style>
html,
body {
  margin: 0;
  padding: 0;
}

div,
header,
footer {
  display: block;
  margin: 0;
  padding: 0;
}

 h1 {
  line-height: 50px;
} 

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg {
  background: url(./assets/home_bg_tel_2.png) no-repeat;
  background-size: 100% 102%;
  color: #fff;
  padding: 100px 0px;
  display: flex;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

#app {
  font-family: Helvetica, Charter, PingFang SC, Microsoft YaHei, Arial, sans-serif;
  color: #222;
  font-size: 22px;
  line-height: 30px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  box-sizing: content-box;
}

.main {
  width: 100%;
}

.waper {
  padding: 0 50px;
  margin-bottom: 100px;
}

footer {
  font-size: 16px;
  color: #222;
  text-align: center;
  bottom: 0;
  width: 100%;
}


.waper .left-part {
  box-sizing: inherit;
  outline: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  /* width: 50%; */
  /* border: 2px solid #fff; */
}

.waper .right-part {
  width: 600px;
  min-width: 500px;
  box-sizing: inherit;
  outline: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  /* border: 2px solid #fff; */
}

.description {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  padding: 0;
  margin-top: 20px;
}

.description li {
  width: 110px;
  height: 95px;
  font-size: 20px;
  border: 4px solid #fff;
  border-radius: 50%;
  text-align: center;
  margin: 10px 15px;
  display: inline-block;
  position: relative;
  padding-top: 15px;
  vertical-align: middle;
}

.description strong {
  font-size: 30px;
  font-weight: 700;
}

.description span {
  font-size: 15px;
  font-weight: 600;
}

.down-wrapper {
  margin-top: 30px;
  display: box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.down-wrapper .btn-down {
  width: 300px;
  min-width: 300px;
  padding: 10px 10px;
  text-align: center;
  color: #4853e3;
  background: -webkit-linear-gradient(top, #fff, #eee);
  background: linear-gradient(180deg, #fff, #eee);
  border-radius: 50px;
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  margin: 10px 20px;
  cursor: pointer;
}

.down-wrapper .btn-down i {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.down-wrapper .btn-down .icon-gp {
  width: 40px;
  height: 40px;
  background: url(./assets/GooglePlay.png) no-repeat;
  background-size: 100%;
}

.down-wrapper .btn-down .icon-android {
  width: 40px;
  height: 40px;
  background: url(./assets/ico_android.png) no-repeat;
  background-size: 100%;
}

.down-wrapper .btn-down span {
  display: inline-block;
  vertical-align: middle;
  font-size: 25px;
  font-weight: 700;
}

.waper .txt {
  text-align: left;
  max-width: 800px;
  margin: 5px auto;
}



.text {
  text-align: left;
  padding: 0 20px;
  box-sizing: border-box;
  flex-basis: 60%;

}

.img {
  margin: 0 10px;
  box-sizing: border-box;
  flex-basis: 35%;
}

@media screen and (max-width: 800px) {
  .waper {
    padding: 0 5px;
  }
  .waper .right-part {
    display: none;
  }
  .text {
    flex-basis: 100%;

  }

  .img {
    flex-basis: 100%;
  }
}

.text li {
  margin: 10px 0;
}

.text span,
.text p {
  color: #000;
  opacity: 0.5;
}



.blingcircle {
  animation: tobig 4s infinite alternate;
  /* 使用动画，并设置动画时长为2秒，往返无限循环 */
}


@keyframes tobig {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
